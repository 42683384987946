// Copyright @yucwang 2021

import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

const MDXPage = ({
  data: {
    mdx: {
      frontmatter: { 
        title, 
        embeddedImagesLocal,
        embeddedImagesRemote },
      body,
    },
  },
}) => {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>BUL | { title }</title>
        </Helmet>
        <MDXProvider>
          <MDXRenderer
            localImages={ embeddedImagesLocal }
            remoteImages={ embeddedImagesRemote }
          >
            { body }
          </MDXRenderer>
        </MDXProvider>
      </Fragment>
    );
};

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        embeddedImagesRemote {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      body
    }
  }
`;

export default MDXPage;
